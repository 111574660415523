import React from "react"

import About from "./About"

const PR = () => {
  return (
    <>
      <About />
    </>
  )
}

export default PR
