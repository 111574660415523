import React from "react"

import Layout from "../components/Layout"
import PR from "../components/PR"
import SEO from "../components/SEO"

const PRPage = () => {
  return (
    <Layout>
      <SEO title="Public Relations" />
      <PR />
    </Layout>
  )
}

export default PRPage
