import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import media from "../../../styles/media"

const Container = styled.div`
  padding: var(--gutter-m) var(--gutter-s) var(--gutter-l) var(--gutter-s);
  ${media.tablet`
    padding: var(--gutter-m) 0 var(--gutter-l) var(--gutter-l);
  `}
`

const Title = styled.div`
  margin-bottom: var(--gutter-m);
  h1 {
    font-size: 2rem;
    line-height: 1;
    transform: translateY(30%);
    position: relative;
    z-index: 2;
  }
  h3 {
    padding-top: 40px;
    font-size: 1rem;
  }
  ${media.tablet`
    margin-bottom: var(--gutter-l);
    h1 {
      font-size: 4rem;
    }
    h3 {
      font-size: 2rem;
    }
  `}
  ${media.laptop`
    h1 {
      font-size: 5.5rem;
    }
    h3 {
      font-size: 2rem;
    }
  `}
`

const Body = styled.div`
  margin-bottom: var(--gutter-m);
  a {
    color: var(--carnation);
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
    &.italic {
      font-style: italic;
    }
  }
  h3 {
    padding-bottom: 10px;
    font-size: 1rem;
  }
  h4 {
    font-size: 1rem;
    padding-bottom: 10px;
    font-family: "circular-std-medium";
    &.italic {
      font-style: italic;
    }
  }
  ${media.tablet`
    margin-bottom: var(--gutter-l);
    max-width: 65vw;
    h3 {
      font-size: 2rem;
    }
    h4 {
      font-size: 2rem;
    }
  `}
`

const About = () => {
  return (
    <Container>
      <Title>
        <h1 className="font-black">OFFICIAL ANNOUNCEMENT:</h1>
      </Title>
      <Body>
        <h3>THE SOUTH AFRICAN INTERNATIONAL TATTOO CONVENTION 2023.</h3>
        <p>
          The South African International Tattoo Convention (SAITC) returns in
          2023 for it&apos;s 4th annual show - this time bringing the global
          trade of tattooing back to Cape Town, and also to Johannesburg for the
          first time! With its worldwide recognition, high standards and variety
          in attending artists, The South African International Tattoo
          Convention has become an authority in representing world class
          tattooing to South Africans. A highly respected event amongst
          international and local artists as one of the most highlighted events
          in the world tattoo convention calendar and the only one if its kind
          in Africa, SAITC&apos;s aim is to create a meeting place in the form
          of a professional environment for the tattoo community to display
          their art for all to admire and learn from. The SAITC inspires South
          African artists to progress, evolve and grow in their craft by getting
          a chance to share and exchange knowledge about the best practices in
          the arts of tattooing with people from all walks of life, all over the
          world. The convention represents an enormous amount of artistic
          talent, within a massive variety of styles of work.
        </p>
        <h3>WHAT TO EXPECT AT #SAITC2023?</h3>
        <p>
          In 2023, the SAITC will showcase over 140 world class tattoo artists -
          all chosen as the best in their field and style of work - with a
          collection of tattooers representing over 22 countries across the
          world! SAITC promises to create a highly professional environment
          whilst partnering with only the best in medical waste management,
          sterilisation, tattoo supplies and to present a highly professional
          standard of tattoo practices. World renowned tattoo artists at the
          convention include the likes of Austin Maples (San Francisco, USA),
          Waldo Del Rocca (Cape Town, South Africa), Daniele Scafati (Rome,
          Italy), Squid Yu (Taiwan), Bob Geerts (Eindhoven, Netherlands), and
          many more! Visit the Artist Page on our website for the full line-up{" "}
          <Link className="mouse-link" to="/artists">
            HERE.
          </Link>
        </p>
        <h3>#SAITC2023 | IT&apos;S AN EXPERIENCE.</h3>
        <p>
          Whether you are a tattoo enthusiast or curious by-stander, SAITC
          offers a rounded experience that not only compliments the tattoo
          culture, but celebrates the wonderful gathering of old and new friends
          from all parts of the world in the form of artists and community. Come
          and get tattooed by the world&apos;s best tattoo artists right on your
          doorstep, and become part of an inspiring, heartwarming community.
        </p>
        <h3>EVERYONE IS WELCOME.</h3>
        <p>
          The 3 day convention has upscaled their offering in 2023: Not only can
          the public come and experience the best in the world in terms of
          tattooing, but over the course of the 3 days there will be live bands
          keeping the crowds entertained such as Fuzigish (JHB) and many more
          (line-ups soon to be announced), coffee roasters and a fully kitted
          beer garden and bar - to name only a few of the highlights. Everyone
          is welcome, including children of all ages. "We are most excited to
          see our community all under one roof, celebrating our love for
          tattooing and all that surrounds it. The public can look forward to
          see world class tattooing, local bands, street food and much more...
          with good vibes only!" - Romy van Tonder, SAITC Organiser.
        </p>
        <h3>HOW TO GET TATTOOED AT #SAITC2023:</h3>
        <p>
          Conventions have a &apos;WALK UP&apos; culture - you just walk up to
          an artist on the spot. Most of the tattooers will have space to tattoo
          you. However, if you have your heart dead set and want to be sure your
          favourite artist will have time, you can pre-book by contacting them
          directly via our website Artist Page which you can find{" "}
          <Link className="mouse-link" to="/artists">
            HERE.
          </Link>{" "}
          The best time to pre-book is from around a month before the event. If
          you don&apos;t get a response (as the tattooers are often traveling),
          we encourage you to buy a weekend pass, show up early when the doors
          open and chat to the artist, as they usually will make time for you at
          some point over the course of the weekend. Plus, with more than 100
          tattooers to choose from, you will be spoilt for choice and may just
          fall in love with something you did not expect!
        </p>
        <h3 className="font-black">
          DO NOT MISS THE SOUTH AFRICAN INTERNATIONAL TATTOO CONVENTION 2023:
        </h3>
        <h3 className="underlined">JOHANNESBURG</h3>
        <p>
          <strong className="font-black">Date:</strong> 24 - 26 March 2023
          <br />
          <strong className="font-black">Venue:</strong> 1FOX, Newtown,
          Johannesburg
          <br />
          <strong className="font-black">Price:</strong> R235 - R620
          <br />
          <strong className="font-black">Tickets:</strong> Webtickets |{" "}
          <a
            href="https://bit.ly/SAITC2023JHB"
            target="blank"
            className="mouse-link"
          >
            https://bit.ly/SAITC2023JHB
          </a>
        </p>
        <h3 className="underlined">CAPE TOWN</h3>
        <p>
          <strong className="font-black">Date:</strong> 31 March - 1 April 2023
          <br />
          <strong className="font-black">Venue:</strong> The Lookout, V&A
          Waterfront, Cape Town
          <br />
          <strong className="font-black">Price:</strong> R235 - R620
          <br />
          <strong className="font-black">Tickets:</strong> Webtickets |{" "}
          <a
            href="https://bit.ly/SAITC2023CT"
            target="blank"
            className="mouse-link"
          >
            https://bit.ly/SAITC2023CT
          </a>
        </p>
        <h3>MORE ABOUT THE VENUES:</h3>
        <p>
          <strong className="font-black">
            THE LOOKOUT, V&A WATERFRONT, CAPE TOWN:
          </strong>
          <br />
          The Lookout represents the direction in which SAITC has grown. This
          bright and welcoming venue appeals to a broad audience of tattoo
          enthusiasts with areas for activations, exhibitions, food, live music
          and fashion vendors. Light pours into this beautiful space where
          tattoo artists&apos; work can be viewed and admired by show-goers. The
          venue also allows for improved flow between indoor and outdoor spaces
          where bars and seating areas integrate seamlessly into the event. The
          deck bar area welcomes guests to linger a little longer and enjoy the
          view of the ocean accompanied by music and some of the best craft
          spirits and beers South Africa has to offer.
        </p>
        <p>
          <strong className="font-black">1FOX, NEWTOWN, JOHANNESBURG:</strong>
          <br />
          Situated in Johannesburg&apos;s inner-city, Newtown forms the heart of
          the city&apos;s regeneration and reinvention of itself. With our new,
          exciting venture into Johannesburg, 1Fox is set in a historic
          warehouse that dates back to the city&apos;s gold mining roots and is
          rumoured to be the original site of one of the first gold rush bars.
          The unique warehouse with its luxurious space offers easy access,
          indoor eating and several different bars & food stalls, plus space for
          live music.
        </p>
        <p>
          <strong className="font-black">
            THE SOUTH AFRICAN INTERNATIONAL TATTOO CONVENTION CONTACT:
          </strong>
          <br />
          <strong className="font-black">Website:</strong>{" "}
          <a
            href="https://www.southafricantattooconvention.com/"
            target="blank"
            className="mouse-link"
          >
            https://www.southafricantattooconvention.com
          </a>
          <br />
          <strong className="font-black">Facebook:</strong>{" "}
          <a
            href="https://www.facebook.com/SATattooConvention"
            target="blank"
            className="mouse-link"
          >
            https://www.facebook.com/SATattooConvention
          </a>
          <br />
          <strong className="font-black">Instagram:</strong>{" "}
          <a
            href="www.instagram.com/SA_TattooConvention"
            target="blank"
            className="mouse-link"
          >
            www.instagram.com/SA_TattooConvention
          </a>
          <br />
          <strong className="font-black">General Enquiries:</strong>
          Waldo Binneman |{" "}
          <a
            href="mailto:waldo@southafricantattooconvention.com"
            className="mouse-link"
          >
            waldo@southafricantattooconvention.com
          </a>
          <br />
          <strong className="font-black">Media Enquiries:</strong> Rachelle
          Crous |{" "}
          <a href="mailto:rachelle@rcpublicity.com" className="mouse-link">
            rachelle@rcpublicity.com
          </a>
        </p>
      </Body>
    </Container>
  )
}

export default About
